import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import VueAxios from 'vue-axios'
import axios from 'axios'



// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css' //theme
import 'primevue/resources/themes/mdc-light-deeppurple/theme.css'


import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'primeflex/primeflex.css'

const app = createApp(App);
app.config.globalProperties.$router = router;

app.config.globalProperties.session = {
    IsOnline : false,
}


window.$ = window.jQuery = require('jquery');
window.JQuery = require('jquery')


import PrimeVue from 'primevue/config';

import Button from 'primevue/button';
app.component('Button', Button);

import InputText from 'primevue/inputtext';
app.component('InputText', InputText);

import InputMask from 'primevue/inputmask';
app.component('InputMask', InputMask);

import InputNumber from 'primevue/inputnumber';
app.component('InputNumber', InputNumber);
 
import Tag from 'primevue/tag';
app.component('Tag', Tag);

import Password from 'primevue/password';
app.component('Password', Password);

import Textarea from 'primevue/textarea';
app.component('Textarea', Textarea);

import Checkbox from 'primevue/checkbox';
app.component('Checkbox', Checkbox);

import Dropdown from 'primevue/dropdown'
app.component('Dropdown', Dropdown);

import Card from 'primevue/card'
app.component('Card', Card);

import Dialog from 'primevue/dialog';
app.component('Dialog', Dialog);

import Menubar from 'primevue/menubar'
app.component('Menubar', Menubar);

import Menu from 'primevue/menu';
app.component('Menu', Menu);

import Chart from 'primevue/chart'
app.component('Chart', Chart);

import DataTable from 'primevue/datatable'
app.component('DataTable', DataTable);

import Column from 'primevue/column';
app.component('Column', Column);

import ColumnGroup from 'primevue/columngroup';
app.component('ColumnGroup', ColumnGroup);

import Toast from 'primevue/toast';
app.component('Toast', Toast);
import ToastService from 'primevue/toastservice';
app.use(ToastService);

import Timeline from 'primevue/timeline';
app.component('Timeline', Timeline);

import ProgressSpinner from 'primevue/progressspinner';
app.component('ProgressSpinner', ProgressSpinner);

import ConfirmPopup from 'primevue/confirmpopup';
app.component('ConfirmPopup', ConfirmPopup);

import ConfirmDialog from 'primevue/confirmdialog';
app.component('ConfirmDialog', ConfirmDialog);

import Calendar  from 'primevue/calendar';
app.component('Calendar',Calendar );

import Listbox from 'primevue/listbox';
app.component('Listbox',Listbox );

import MultiSelect from 'primevue/multiselect';
app.component('MultiSelect', MultiSelect );

import Sidebar from 'primevue/sidebar';
app.component('Sidebar',Sidebar );

import Toolbar from 'primevue/toolbar';
app.component('Toolbar',Toolbar );

import TabMenu from 'primevue/tabmenu';
app.component('TabMenu',TabMenu );

import RadioButton from 'primevue/radiobutton';
app.component('RadioButton',RadioButton );

import Avatar from 'primevue/avatar';
app.component('Avatar',Avatar );

import ScrollPanel from 'primevue/scrollpanel';
app.component('ScrollPanel',ScrollPanel );

import Panel from 'primevue/panel';
app.component('Panel',Panel );

import PickList from 'primevue/picklist';
app.component('PickList',PickList );

import Tree from 'primevue/tree';
app.component('Tree',Tree );

import Divider from 'primevue/divider';
app.component('Divider',Divider );

import Image from 'primevue/image';
app.component('Image', Image );

import FileUpload from 'primevue/fileupload';
app.component('FileUpload', FileUpload );

import ProgressBar from 'primevue/progressbar';
app.component('ProgressBar', ProgressBar );

import Galleria from 'primevue/galleria';
app.component('Galleria', Galleria );

import Rating from 'primevue/rating';
app.component('Rating', Rating );

import BlockUI from 'primevue/blockui';
app.component('BlockUI', BlockUI );

// import Stepper from 'primevue/stepper';
// app.component('Stepper',Stepper );

// import StepperPanel from 'primevue/stepperpanel';
// app.component('StepperPanel',StepperPanel );

// import as component
// import Badge from 'primevue/badge';
// app.directive('Badge', Badge);

// import as directive
import BadgeDirective from 'primevue/badgedirective';
app.directive('Badge', BadgeDirective);


// -------------------------------------------------
import VueScrollTo from 'vue-scrollto';
app.use(VueScrollTo);

app.use(PrimeVue, { ripple: true },  { zIndex : { Menubar: 999999, ScrollPanel: 9999 } });
app.use(router);
app.use(store);

import ConfirmationService from 'primevue/confirmationservice';
app.use(ConfirmationService);
app.use(VueAxios, axios);


app.mount('#app');