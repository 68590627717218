<template>
  <div class="layout">
    <transition name="fade" mode="out-in">
         
        <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  components: {
  },
}
</script>

<style scoped>
.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: var(--gray-300);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>